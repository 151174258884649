.SocialLink {
  margin: .5rem;
  padding: .5rem .75rem;
  border-radius: .5rem;
}

.SocialLink:hover .SocialLinkIcon,
.SocialLink:active .SocialLinkIcon {
  color: #E34038;
}

.SocialLinkIcon {
  color: white;
  font-size: 3em;
}