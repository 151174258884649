.App {
  height: 100%;
  background-color: #222A38;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SocialLinkBox {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}
